import React, { Component } from "react";
import "./../App.css";
import PlaylistRow from "./PlaylistRow";
import { Form, FormGroup } from "reactstrap";

class PlaylistsList extends Component {
  render() {
    let noUpdateInfo = [];
    let oftenUpdated = [];
    let seldomUpdated = [];
    let text;
    let textForSeldomUpdated;

    if (this.props.playlists[0].updated === null) {
      this.props.playlists.forEach((playlist) => {
        noUpdateInfo.push(
          <PlaylistRow
            playlist={playlist}
            onClick={(checked) => this.props.handler(playlist, checked)}
            key={playlist.sid}
          />
        );
      });
      text = "Here you go.";
    } else {
      this.props.playlists.forEach((playlist) => {
        if (playlist.updated) {
          oftenUpdated.push(
            <PlaylistRow
              playlist={playlist}
              onClick={(checked) => this.props.handler(playlist, checked)}
              key={playlist.sid}
            />
          );
        } else {
          seldomUpdated.push(
            <PlaylistRow
              playlist={playlist}
              onClick={(checked) => this.props.handler(playlist, checked)}
              key={playlist.sid}
            />
          );
        }
      });
      text =
        "Here's all the playlists you follow. Click on playlist to select. You can always edit them later.";
      if (seldomUpdated.length) {
        textForSeldomUpdated =
          "Fyi, these ones were not updated at all in the last three months. You can still add them, of course.";
      }
    }
    return (
      <div>
        <div>
          {text}
          <br />
        </div>
        <Form>
          <FormGroup check>{noUpdateInfo}</FormGroup>
        </Form>
        <div>
          <Form>
            <FormGroup check>{oftenUpdated}</FormGroup>
          </Form>
        </div>
        <div>{textForSeldomUpdated}</div>
        <Form>
          <FormGroup check>{seldomUpdated}</FormGroup>
        </Form>
      </div>
    );
  }
}

export default PlaylistsList;
