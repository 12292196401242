import React, { Component } from "react";
import "./../App.css";
import { Label, Input } from "reactstrap";

class PlaylistRow extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      checked: JSON.parse(this.props.playlist.chosen)
    };
  }

  handleClick() {
    this.setState({
      checked: !this.state.checked,
    }, () => this.props.onClick(this.state.checked));
  }

  render() {
    const playlist = this.props.playlist;
    const playlistCover = playlist.cover;
    const playlistName = playlist.name;

    return (
      <div>
        <Label
          className= {this.state.checked ? "PlaylistRow checked" : "PlaylistRow"}
        >
          <Input type="checkbox"
          className="Check"
          onChange={this.handleClick}
          checked={this.state.checked}
             />{" "}
          <span className="spanCheck">✓ </span>
          <img
            src={playlistCover}
            width={50}
            height={50}
            alt="playlist cover"
          />{" "}
          {playlistName}
        </Label>
      </div>
    );
  }
}

export default PlaylistRow;
