import React, { Component } from "react";
import "./App.css";
import { Spinner } from "reactstrap";
import InitialLogin from "./login/InitialLogin";
import PlaylistsPage from "./components/PlaylistsPage";
import ErrorLogin from "./login/ErrorLogin";
import Header from "./components/Header";
import SubmitPage from "./components/SubmitPage";

class App extends Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goToMainPage = this.goToMainPage.bind(this);
    this.handleWaitForSubmit = this.handleWaitForSubmit.bind(this);
    this.state = {
      accessToken: "",
      showErrorLoginPage: false,
      currentPath: window.location.pathname,
      showSubmitPage: false,
      showWaitingPage: false,
    };
  }

  componentDidMount() {
    if (this.state.currentPath === "/playlists/") {
      this.getAccessToken();
    }
  }

  // in case of errors redirect to login page again
  handleError() {
    this.setState({ accessToken: null });
    this.setState((prevState) => ({
      showErrorLoginPage: !prevState.showErrorLoginPage,
    }));
  }

  handleSubmit() {
    this.setState((prevState) => ({
      showSubmitPage: !prevState.showSubmitPage,
    }));
  }

  goToMainPage() {
    this.setState({
      currentPath: "/",
    });
    this.setState({ accessToken: null });
  }

  handleWaitForSubmit() {
    this.setState((prevState) => ({
      showWaitingPage: !prevState.showWaitingPage,
    }));
  }

  render() {
    let page;
    let text;

    if (this.state.currentPath === "/") {
      page = <InitialLogin />;
    } else if (this.state.showSubmitPage) {
      page = <SubmitPage mainPageRedirect={this.goToMainPage} />;
    } else if (this.state.showWaitingPage) {
      page = (
        <div className="Loader">
          <br />
          <Spinner color="dark" />
        </div>
      );
      text = "Saving your playlists, might take a while";
    } else if (!this.state.showErrorLoginPage && this.state.accessToken) {
      page = (
        <PlaylistsPage
          accessToken={this.state.accessToken}
          playlistsError={this.handleError}
          submitError={this.handleError}
          submitSuccessful={this.handleSubmit}
          waitForSubmit={this.handleWaitForSubmit}
          mainPageRedirect={this.goToMainPage}
        />
      );
    } else if (this.state.showErrorLoginPage) {
      page = <ErrorLogin />;
    } else {
      page = (
        <div className="Loader">
          <Spinner color="dark" />
        </div>
      );
    }
    return (
      <div>
        <Header />
        <div className="App">
          {text} {page}
        </div>
        <div className="Footer"></div>
      </div>
    );
  }

  async getAccessToken() {
    //retrieving authorization code
    const urlParams = new URLSearchParams(window.location.search);
    const authCodeParam = urlParams.get("code");
    // exchanging authorization code to access token
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ code: authCodeParam }),
    };
    const response = await fetch("/auth", requestOptions);
    if (response.ok) {
      const token = await response.text();
      this.setState({ accessToken: token });
    } else {
      this.handleError();
    }
  }
}

export default App;
