import React from "react";
import { Button } from "reactstrap";

export const authEndpoint = "https://accounts.spotify.com/authorize";
const clientId = "836ddfe846db4100acfa820a024f15e2";
const redirectUri = "http://playlistmanager.10cats.com/playlists";
const scopes = [
  "playlist-read-collaborative",
  "playlist-read-private",
  "playlist-modify-private",
  "ugc-image-upload",
];

const loginButton = (
  <Button
    outline
    color="success"
    className="LoginBtn"
    href={`${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
      "%20"
    )}&response_type=code`}
  >
    login with Spotify!
  </Button>
)

export default loginButton;
