import React, { Component } from "react";
import { Button } from "reactstrap";
import "./../App.css";

class SubmitPage extends Component {
  render() {
    return (
      <div>
        <div>
          Done! Your playlist will be updated each Tuesday and Friday around
          14pm.
        </div>
        <div>
          <Button
            className="RedirectBtn"
            color="link"
            onClick={this.props.mainPageRedirect}
          >
            Return to the home page
          </Button>
        </div>
      </div>
    );
  }
}

export default SubmitPage;
