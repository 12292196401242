import React from "react";

const welcomeText = (
  <div>
    <h4>Like new music?</h4>
    <h5>
      {" "}
      Now all the fresh adds from the playlists you follow can be collected in
      one playlist!
    </h5>
    <h5>
      {" "}
      Introducing{" "}
      <a style={{ color: "#660066", fontWeight: "bold" }}>
        Playlist Manager
      </a>
      — the most convient way to get new tracks:
    </h5>
    <ol>
      <li>Choose the playlists you want to get updates from.</li>
      <li> The app will create your personal private playlist.</li>
      <li>
        All new tracks from the playlists you've chosen will appear there!
      </li>
    </ol>
  </div>
);

const faq = (
  <div className="Faq">
    <h6> Wait, I have some questions!</h6>
    <br />
    <ul className="FaqList">
      <li>
        <b>Can I unfollow the playlists i've chosen?</b>
      </li>
      <li>
        The app only tracks the playlists you are following. Therefore if you
        unfollow a playlist, it will not be tracked anymore.
      </li>
      <br />
      <li>
        <b>Can I edit my choice later ?</b>
      </li>
      <li>Yes! Just click the "login with Spotify" button.</li>
      <br />
      <li>
        <b>How often will I get updates?</b>
      </li>
      <li>Each tuesday and friday.</li>
      <br />
      <li>
        <b>What if I delete a track?</b>
      </li>
      <li>
        It won't appear again, even if it gets added to other playlists.
        Convient, isn't it?
      </li>
      <br />
      <li>
        <b> What happens if I delete the playlist?</b>
      </li>
      <li>That's it, you can forget it ever existed.</li>
      <br />
      <li>
        <b> Can I edit name, picture or description of my playlist?</b>
      </li>
      <li>Sure!</li>
      <br />
      <li>
        <b> Can I see from which playlist a track came?</b>
      </li>
      <li>Maybe in the next version:3</li>
    </ul>
  </div>
);

export { welcomeText, faq };
