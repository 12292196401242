import React from "react";
const Header = () => {
  return (
    <header className="Header">
      <div className="HeaderText">
        <img className="HeaderPic" src={"/header.png"} alt="headerpic" />
        Playlist Manager
      </div>
    </header>
  );
};
export default Header;
