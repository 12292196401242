import React, { Component } from "react";
import "./../App.css";
import loginButton from "./../components/LoginButton.js";

class ErrorLogin extends Component {

render() {
  return (
    <div>
    Something went wrong. Sorry! Wanna try again?
    <br/>
        {loginButton}
       </div>
  );
  }
}

export default ErrorLogin;
