import React, { Component } from "react";
import "./../App.css";
import PlaylistsList from "./PlaylistsList";
import SubmitButton from "./SubmitButton";
import { Spinner } from "reactstrap";
import { Button } from "reactstrap";

class PlaylistsPage extends Component {
  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
    this.state = {
      userHasNoPlaylists: false,
      accessToken: "",
      playlists: [],
      chosenPlaylists: [],
    };
  }

  componentDidMount() {
    this.getPlaylists(this.props.accessToken).then(() =>
      this.state.playlists.forEach((playlist) => {
        if (JSON.parse(playlist.chosen)) {
          this.setState({
            chosenPlaylists: this.state.chosenPlaylists.concat([playlist]),
          });
        }
      })
    );
  }

  handler(playlist, checked) {
    // if checked and not in array, add
    if (checked && !this.state.chosenPlaylists.includes(playlist.sid)) {
      this.setState(
        {
          chosenPlaylists: this.state.chosenPlaylists.concat([playlist]),
        },
        () => console.log(this.state.chosenPlaylists)
      );
    }
    // if unchecked, remove from array
    else if (!checked) {
      let chosen = this.state.chosenPlaylists.filter(
        (p) => p.sid !== playlist.sid
      );
      this.setState({ chosenPlaylists: chosen });
    }
  }

  render() {
    let page;
    let element;

    if (this.state.userHasNoPlaylists) {
      page = "Seems like you're not following any playlists at the moment.";
      element = (
        <Button
          className="RedirectBtn"
          color="link"
          onClick={this.props.mainPageRedirect}
        >
          Return to the home page
        </Button>
      );
    } else if (this.state.playlists && this.state.playlists.length) {
      page = (
        <PlaylistsList
          playlists={this.state.playlists}
          handler={this.handler}
        />
      );
      element = (
        <SubmitButton
          chosenPlaylists={this.state.chosenPlaylists}
          accessToken={this.state.accessToken}
          submitError={this.props.submitError}
          submitSuccessful={this.props.submitSuccessful}
          waitForSubmit={this.props.waitForSubmit}
        />
      );
    } else {
      page =
        "Ok, give me some time to load your playlists. (Might take ~ 30 seconds)";
      element = (
        <div className="Loader">
          <Spinner color="dark" />
        </div>
      );
    }
    return (
      <div>
        {page}
        <br />
        {element}
      </div>
    );
  }

  async getPlaylists(accessToken) {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: accessToken,
      },
    };
    try {
      const response = await fetch("/allplaylists", requestOptions);
      const playlists = await response.json();
      this.setState({ playlists: playlists });
      this.setState({ accessToken: response.headers.get("Token") });
      if (!playlists.length) {
        this.setState({ userHasNoPlaylists: true });
      }
    } catch (error) {
      this.props.playlistsError();
    }
  }
}

export default PlaylistsPage;
