import React, { Component } from "react";
import "./../App.css";
import { faq } from "./../res/Text";
import WelcomeTextBlock from "./../components/WelcomeTextBlock.js";
import loginButton from "./../components/LoginButton.js";

class InitialLogin extends Component {
  render() {
    return (
      <div>
        <div className="Outer">
          <div>
            {" "}
            <img
              className="Screenshot"
              src={"/screenshot.png"}
              alt="screenshot"
            />{" "}
          </div>
          <div className="Inner">
            <div>
              {" "}
              <WelcomeTextBlock />{" "}
            </div>
            <div className="Login">
              This sounds amazing,
              <br />
              {loginButton}
            </div>
          </div>
        </div>
        {faq}
      </div>
    );
  }
}

export default InitialLogin;
