import React, { Component } from "react";
import "./../App.css";
import { Button } from "reactstrap";

class SubmitButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.waitForSubmit();
    this.sendChosenPlaylists();
  }

  render() {
    return (
      <div className="BtnPadding">
        <Button
          outline
          color="success"
          size="lg"
          className="SubmitBtn"
          onClick={this.handleClick}
        >
          Submit!
        </Button>{" "}
      </div>
    );
  }

  async sendChosenPlaylists() {
    const token = this.props.accessToken;
    const chosenPlaylists = this.props.chosenPlaylists;

    const response = await fetch("/chosenplaylists", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Token: token,
      },
      body: JSON.stringify(chosenPlaylists),
    });
    if (!response.ok) {
      this.props.submitError();
    } else if (response.ok) {
      this.props.submitSuccessful();
    }
  }
}

export default SubmitButton;
